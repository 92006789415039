import React, { useState } from 'react';
import Layout from '../components/layout/Layout';
import ContactRepositories from '../repositories/contact-repositories';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select'
import FloatingLabelInput from '../components/FloatingLabelInput';
import Button from '../components/Button';
import { ScaleLoader } from 'react-spinners';
import FloatingLabelTextArea from '../components/FloatingLabelTextArea';

const ProcessingStatus = {
  nop: 'nop',
  processing: 'processing',
  completed: 'completed',
}
const Index = () => {
  const [isProcessing, setIsProcessing] = useState(ProcessingStatus.nop);
  const { formState: { errors }, handleSubmit, control, reset } = useForm();

  const onInquiry = async (data) => {
    try {
      setIsProcessing(ProcessingStatus.processing);
      await ContactRepositories.createContact(data.name, data.email, data.contactTypeCode, 'aaa', data.contactDetail);
      setIsProcessing(ProcessingStatus.completed);
    } catch {
      setIsProcessing(ProcessingStatus.nop);
    } finally {
      reset();
    }
  };

  const Content = (onInquiry) => {
    const options = [
      { value: 'requestDocument', label: '資料請求', },
      { value: 'requestTrial', label: 'トライアル希望', },
      { value: 'requestDemo', label: '機能デモ・説明希望', },
      { value: 'requestOther', label: 'その他', },
    ]
    return (
      <>
        <div className={'flex flex-col items-start mx-auto w-5/6 sm:w-1/2 md:w-5/12'}>
          <div className={'mt-4 w-full'}>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={ ({field}) => (
                <FloatingLabelInput
                  name={'name'}
                  type={'name'}
                  label={"[必須] お名前"}
                  onChange={(event) => { field.onChange(event.target.value); }}
                />
              )}
            />
          </div>
          {errors.name?.type === 'required' && <div className={'text-red-500 text-sm'}>お名前を入力してください</div>}
          <div className={'mt-4 w-full'}>
            <Controller
              name="email"
              rules={{ required: true, pattern: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/i }}
              control={control}
              render={ ({field}) => (
                <FloatingLabelInput
                  name={'email'}
                  type={'email'}
                  label={"[必須] メールアドレス"}
                  onChange={(event) => { field.onChange(event.target.value); }} />
              )}
            />
          </div>
          {errors.email?.type === 'required' && <div className={'text-red-500 text-sm'}>メールアドレスを入力してください</div>}
          {errors.email?.type === 'pattern' && <div className={'text-red-500 text-sm'}>メールアドレスの形式が正しくありません</div>}
          <div className={'mt-4 w-full'}>
            <Controller
              name="contactTypeCode"
              rules={{ required: true }}
              control={control}
              render={ ({field}) => (
                <Select
                  isSearchable={false}
                  isRtl={false}
                  options={options}
                  value={options?.find((c) => c.value === field.value)}
                  onChange={(val) => {
                    field.onChange(val?.value);
                  }}
                  placeholder="[必須] 問い合わせ内容"
                  styles={{
                    control: (provided) => {
                      return { ...provided, borderColor: 'rgba(203, 213, 224)', borderWidth: '2px' };
                    },
                    singleValue: (provided, _state) => {
                      return { ...provided, textAlign: 'left' };
                    },
                    option: (base) => ({
                      ...base,
                      textAlign: 'left',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      textAlign: 'left',
                      color: 'rgba(160, 174, 192)',
                    }),
                  }}
                />
              )}
            >
            </Controller>
          </div>
          {errors.contactTypeCode?.type === 'required' && <div className={'text-red-500 text-sm'}>問い合わせ内容を入力してください</div>}
          <div className={'mt-4 w-full'}>
            <Controller
              name="contactDetail"
              rules={{ maxLength: 500 }}
              control={control}
              render={ ({field}) => (
                <FloatingLabelTextArea name={'contactDetail'}
                                    label={'お問い合わせ詳細'}
                                    onChange={(event) => { field.onChange(event.target.value); }}
                />
              )}
            />
          </div>
          {errors.contactDetail?.type === 'required' && <div className={'text-red-500 text-sm'}>詳細を入力してください</div>}
          <div className={'mt-4 w-full'}>
            <Button onClick={handleSubmit(onInquiry)} >問い合わせ</Button>
          </div>
        </div>
      </>
    )
  }

  const Loading = () => {
    return (
      <>
        <div className={'mt-8 w-full'}>
          <ScaleLoader widh={10} height={50}/>
        </div>
      </>
    )
  }

  const Thanks = () => {
    return (
      <>
        <div className={'flex justify-center mt-8'}>
          <div className={'w-9/12 sm:w-full'}>お問い合わせありがとうございます。後ほど担当者よりご連絡させていただきます。</div>
        </div>
      </>
    )
  }

  return (
    <>
      <Layout>
        <section id="features" className="py-20 lg:pb-40 lg:pt-24">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">お問い合わせ</h2>
            {
              (() => {
                if (isProcessing === ProcessingStatus.processing) {
                  return Loading()
                } else if (isProcessing === ProcessingStatus.completed) {
                  return Thanks();
                } else if (isProcessing === ProcessingStatus.nop) {
                  return Content(onInquiry)
                }
              })()
            }
          </div>
        </section>
      </Layout>
    </>
  )
}


export default Index;
