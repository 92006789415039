import React from 'react';

const FloatingLabelInput = ({name, type, onChange, label, className}) => {
  return (
    <>
      <div className="relative border-2 border-gray-400 rounded w-full">
        <input
          name={name}
          type={type}
          placeholder=" "
          onChange={onChange}
          className={`block bg-transparent h-8 p-1 w-full ${className}`}
        />
        <label
          htmlFor={name}
          className={'floating-label'}
        >
          {label}
        </label>
      </div>
    </>
  )
}

export default FloatingLabelInput;