import axios from "axios";

class ContactRepositories {

  instance;

  constructor() {
    const baseURL = process.env.API_SERVER_BASE_URL;
    this.instance = axios.create({
      baseURL: baseURL,
    });
  }


  async createContact(name, email, contactTypeCode, contactTypeName, contactDetail) {
    const headers = {
      'Content-Type': 'application/json',
      'X-From': location.href
    };
    await this.instance.post("/contacts", {
      "name": name,
      "email": email,
      "contactTypeCode": contactTypeCode,
      "contactTypeName": contactTypeName,
      "contactDetail": contactDetail
    },
    {
      headers: headers,
    });
  }
}


export default new ContactRepositories();
